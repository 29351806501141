import React from 'react'
import Index from '../..'
import { NavLink, useNavigate } from 'react-router-dom'


const StaffSideBar = () => {

    const links = [
        {
            title: "Dashboard",
            icon: <Index.DashboardOutlined />,
            navigate: '/clinic/dashboard',
        },
        {
            title: "Patient",
            icon: <Index.AccessibilityNewIcon />,
            navigate: '/clinic/patient/list'
        },
        {
            title: "Doctors",
            // icon: <Index.AccessibilityNewIcon />,
            icon: <Index.MedicalServicesOutlined />,
            navigate: '/clinic/doctors/list'
        },
        {
            title: "Disable Slots",
            // icon: <Index.AccountCircleOutlinedIcon />,
            icon: <Index.AlarmOffIcon/>,
            navigate: '/clinic/Doctor_non_availability/list'
        },
        {
            title: "Appointments",
            // icon: <Index.AccountCircleOutlinedIcon />,
            icon: <Index.ContactPhoneOutlinedIcon />,
            navigate: '/clinic/appointment/list'
        },
        {
            title: "Medicine",
            icon: <Index.MedicationLiquidIcon />,
            navigate: '/clinic/medicine/list'
        },
    ]
const navigate = useNavigate()
    return (
        <>
            <Index.Card className="h-[calc(100vh-2rem)] w-full max-w-[16rem] p-4 shadow-xxl bg-[#151718] rounded-none">
                <div className="mb-2 p-4">
                    <Index.Typography variant="h5" color="blue-gray">

                    </Index.Typography>
                </div>
                <Index.List>
                    {links.map((link) => {
                        return (
                            <>
                                <NavLink to={link.navigate}>
                                    <Index.ListItem className='font-serif text-xl text-[#8D8585] hover:text-[#FFFFFF] hover:bg-transparent rounded-none active:bg-transparent focus:bg-transparent focus-visible:border-l-4 focus:text-[#FFFFFF] active:text-[#FFFFFF] highlight'>
                                        <Index.ListItemPrefix>
                                            {/* <Index.DashboardIcon /> */}
                                            {link.icon}
                                        </Index.ListItemPrefix>
                                        {link.title}
                                    </Index.ListItem>
                                </NavLink>
                            </>
                        )
                    })}
                </Index.List>
                <Index.Card className='lg:h-[14vw] mt-4 p-2 flex items-center justify-center'>
                    <Index.BusinessCenterIcon className='text-black' sx={{fontSize:50}}/>
                    <Index.Typography variant='h5' className='text-black mt-2'>Appointment</Index.Typography>
                    <Index.Typography>Would you like to make a</Index.Typography>
                    <Index.Typography>new appointment to ?</Index.Typography>
                    <Index.Button className='bg-black mt-2' onClick={()=>{navigate('/clinic/appointment/list')}}>Book an App</Index.Button>
                </Index.Card>
            </Index.Card></>
    )
}

export default StaffSideBar;