import React, {useState} from 'react'
import ClinicLayout from '../../../components/Layout/Clinic/ClinicLayout'
import { MagnifyingGlassIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Skeleton from 'react-loading-skeleton'
import { createMedia } from "@artsy/fresnel"
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux';
import {DeleteAppointmentAction ,GetAppointmentListAction} from '../../../redux/Clinic/clinicActions';
// import AddDoctorAvailabilities from './AddDoctorAvailability';
// import AddAppointments from '../Appointments/AddAppointment';
import DoctorsNameList from './Doctorsnamelist';
import { useNavigate } from 'react-router-dom';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})

const TABLE_HEAD = ["Patient Name","Appointment Date", "Appointment Time", "Doctor Name"];


const DoctorAvailabilityList = () => {
  const accessToken = useSelector(state => state.login.accessToken)
  const appointmentList = useSelector(state => state.clinic.appointmentList)


  const dispatch = useDispatch();
  const navigate = useNavigate();

  function convertToHumanTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let formattedHours = (parseInt(hours, 10) % 12) || 12;
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    const humanTime = `${formattedHours}:${minutes} ${meridiem}`;
    return humanTime;
  }

  const [search, setSearch ] = useState('');
  console.log(search)

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);


  let x=appointmentList

  if (typeof(appointmentList)!="undefined"){
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = appointmentList.slice(indexOfFirstItem, indexOfLastItem);
  x=currentItems
  console.log(currentItems)
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  
  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  

  const DeleteAppointmentButton = (staffId) => {
    let data = {
      id: staffId
    }
    dispatch(DeleteAppointmentAction(data, accessToken, navigate))
  }

  React.useEffect(() => {
    dispatch(GetAppointmentListAction(accessToken))
    
  }, [])

// Pagination

// const [active, setActive] = React.useState(1);
// const itemsPerPage = 4; 

// const getItemProps = (index) => ({
//   variant: active === index ? "filled" : "text",
//   color: active === index ? "blue" : "blue-gray",
//   onClick: () => setActive(index),
//   className: "rounded-full",
// });

// const next = () => {
//   if (active === Math.ceil(appointmentList.length / itemsPerPage)) return;
//   setActive(active + 1);
// };

// const prev = () => {
//   if (active === 1) return;
//   setActive(active - 1);
// };

  // const currentPageData = appointmentList((data, index) => index >= (active - 1) * 2 && index < active * 3);

console.log(appointmentList,"checklist")

// let y=appointmentList

// if (typeof(appointmentList)!="undefined"){
//  const currentPageData =appointmentList.filter((data, index) => index >= (active - 1) * 3 && index < active * 3);
//  y=currentPageData;
//  console.log(currentPageData,"s")
// const currentPageData = appointmentList.slice(
//   (active - 1) * itemsPerPage,
//   active * itemsPerPage
// );
// y=currentPageData
//  console.log(currentPageData,"s")
// };
if(appointmentList != null){
  return (
    <>
      <ClinicLayout>
        <Card className="h-full w-full ">
          <CardHeader floated={false} shadow={false} className="rounded-none"> 
            <div className="mb-4 flex items-center justify-between gap-4 lg:gap-8">
              <div>
                <MediaContextProvider>
                  <Media greaterThanOrEqual='lg'>
                <Typography variant="h3" color="blue-gray" className="font-serif">
                  Appointment List
                </Typography>
                <Typography color="gray" className="mt-1 text-lg font-serif">
                  See information about all appointments
                </Typography>
                </Media>
                <Media at='sm'>
                <Typography variant="h5" color="blue-gray" className="font-serif">
                  Appointment List
                </Typography>
                <Typography color="gray" className="mt-1 text-lg font-serif">
                  See information about all appointments
                </Typography>
                </Media>
                </MediaContextProvider>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                {/* <Button variant="outlined" color="blue-gray" size="sm">
                  view all
                </Button> */}
                {/* <AddAppointments userDetails={false} /> */}
                <DoctorsNameList/>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <Tabs value="all" className="w-full md:w-max">
                {/* <TabsHeader>
                  {TABS.map(({ label, value }) => (
                    <Tab key={value} value={value}>
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader> */}
              </Tabs>
              <div className="w-full md:w-72">
                <Input label="Search" onChange={(e) => setSearch(e.target.value)} icon={<MagnifyingGlassIcon className="h-5 w-5" />} />
              </div>
            </div>
          </CardHeader>
          <CardBody className="px-0 lg:h-3/5 overflow-x-scroll overflow-y-hidden lg:overflow-hidden">
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head, index) => (
                    <th
                      key={head}
                      className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="flex items-center justify-between gap-2 font-bold text-lg leading-none opacity-70"
                      >
                        {head}{" "}
                        {index !== TABLE_HEAD.length - 1 && (
                          <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                        )}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              {appointmentList.length > 0 ? (
                  <>
              <tbody>
                {x && <>{x.filter((appointment) => {
                  return search.toLowerCase() === '' ? appointment: appointment.patient[1].toLowerCase().includes(search) || appointment.slot.date.includes(search) || appointment.doctor[1].toLowerCase().includes(search) ;
                }).map((appointment, index) => {
                  const isLast = index === appointmentList.length - 1;
                  const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                  return (
                    <tr>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          {/* <Avatar src={img} alt={name} size="sm" /> */}
                          <div className="flex flex-col">
                            <Typography variant="small" color="blue-gray" className="font-normal">
                              {appointment.patient[1]}
                            </Typography>
                            {/* <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal opacity-70"
                            >
                              {appointment.designation}
                            </Typography> */}
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography variant="small" color="blue-gray" className="font-normal">
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {appointment.slot.date}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography variant="small" color="blue-gray" className="font-normal">
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {convertToHumanTime(appointment.slot.slote_no)}
                          </Typography>
                        </div>
                      </td>
                      {/* <td className={classes}>
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={appointment.is_active ? "Active" : "Inactive"}
                            color={appointment.is_active ? "green" : "red"}
                          />
                        </div>
                      </td> */}
                      {/* <td className={classes}>
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {appointment.contact}
                        </Typography>
                        <p className='text-sm text-gray-700'>{appointment.address}</p>

                      </td> */}
                       <td className={classes}>
                        <div className="flex flex-col">
                          <Typography variant="small" color="blue-gray" className="font-normal">
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {appointment.doctor[1]}
                          </Typography>
                        </div>
                      </td>
                      {/* <td className={`${classes} flex space-x-4 flex-row`} >
                        <IconButton className='bg-red-500' onClick={(e) => { DeleteAppointmentButton(appointment) }}>
                          <DeleteOutline />
                        </IconButton>
                      </td> */}
                    </tr>
                  );
                })} </>}
              </tbody>
              </>
                ) : <>
                <Typography
                            variant="small"
                            color="blue-gray"
                            className="text-xl font-serif font-semibold ml-2 opacity-80 mt-5"
                          >
                            No Data Found
                          </Typography>
                </> }
            </table>
          </CardBody>
          {appointmentList.length > 0 ? (
                  <>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            {/* <Typography variant="small" color="blue-gray" className="font-normal">
              Page 1 of 10
            </Typography>
            <div className="flex gap-2">
              <Button variant="outlined" color="blue-gray" size="sm">
                Previous
              </Button>
              <Button variant="outlined" color="blue-gray" size="sm">
                Next
              </Button>
            </div> */}
             <Typography variant="small" color="blue-gray" className="font-normal">
            Page <strong className="text-blue-gray-900">{currentPage}</strong> of{" "}
            <strong className="text-blue-gray-900">{Math.floor(appointmentList.length/itemsPerPage)+1}</strong>
            </Typography>
            <div className="flex gap-2">
              <Button variant="outlined" color="blue-gray" size="sm" onClick={previousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Button variant="outlined" color="blue-gray" size="sm" onClick={nextPage} disabled={x.length < itemsPerPage}>
                Next
              </Button>
            </div>
          </CardFooter>
          </>
                ) : <>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4"></CardFooter>
                </> }
        </Card>
      </ClinicLayout>

    </>
  )
}
return(
  <>
  {/* <div className='flex'>
            <div class="flex-1" className='w-3/12 h-full'>
            <Skeleton height={40} width={250} style={{marginTop: 40, marginLeft:40}}/>
            <Skeleton height={40} width={200} style={{marginTop: 60, marginLeft:40}}/>
            <Skeleton count={4} height={40} width={200} style={{marginTop: 25, marginLeft:40}}/>
            
            </div> */}<MediaContextProvider>
              <Media greaterThanOrEqual='lg'>
            <ClinicLayout>
            <div class="flex-1" className='h-full w-full float-left'>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 40, marginLeft:40}}/> */}
              {/* <Skeleton circle={true} width={50} height={50} style={{marginTop: 20, marginRight: 90, float:'right'}}/> */}
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 60, marginLeft:40}}/> */}
              {/* <Skeleton height={30} width={200} style={{marginTop: 20, marginLeft:30}}/>
              <Skeleton height={40} width={100} inline={true} style={{marginRight:40, float:'right'}}/>
              <Skeleton height={40} width={100} style={{float:'right'}}/>
              <Skeleton height={20} width={300} style={{marginTop: 10, marginLeft:30}}/> */}
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 10, marginLeft:40}}/> */}
              {/* <Skeleton height={30} width={270} inline={true} style={{marginTop: 20, marginLeft:30}}/>
              <Skeleton height={30} width={270} style={{marginTop: 20, marginRight:40, float:'right'}}/> */}
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 0, marginLeft:40}}/> */}
              <CardHeader floated={false} shadow={false} className="rounded-none"> 
            <div className="mb-4 flex items-center justify-between gap-8">
              <div>
                <Typography variant="h3" color="blue-gray" className="font-serif">
                  Appointment List
                </Typography>
                <Typography color="gray" className="mt-1 text-lg font-serif">
                  See information about all appointments
                </Typography>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                {/* <Button variant="outlined" color="blue-gray" size="sm">
                  view all
                </Button> */}
                {/* <AddAppointments userDetails={false} /> */}
                <DoctorsNameList/>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <Tabs value="all" className="w-full md:w-max">
                {/* <TabsHeader>
                  {TABS.map(({ label, value }) => (
                    <Tab key={value} value={value}>
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader> */}
              </Tabs>
              <div className="w-full md:w-72">
                <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} />
              </div>
            </div>
          </CardHeader>
              <Skeleton count={4} height={40} width={310} inline={true} style={{marginTop: 40, marginLeft:2}}/>
              <Skeleton height={30} width={1200} style={{marginTop: 40, marginLeft:30}}/>
              <Skeleton count={6} height={30} width={1200} style={{marginTop: 10, marginLeft:30}}/>
              
              
            </div>
            </ClinicLayout>
            </Media>
            <Media at='sm'>
            <ClinicLayout>
            <div class="flex-1" className='h-full w-full float-left'>
             
              <CardHeader floated={false} shadow={false} className="rounded-none"> 
            <div className="mb-4 flex items-center justify-between gap-8">
              <div>
                <Typography variant="h5" color="blue-gray" className="font-serif">
                  Appointment List
                </Typography>
                <Typography color="gray" className="mt-1 text-lg font-serif">
                  See information about all appointments
                </Typography>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                {/* <Button variant="outlined" color="blue-gray" size="sm">
                  view all
                </Button> */}
                {/* <AddAppointments userDetails={false} /> */}
                <DoctorsNameList/>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <Tabs value="all" className="w-full md:w-max">
                {/* <TabsHeader>
                  {TABS.map(({ label, value }) => (
                    <Tab key={value} value={value}>
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader> */}
              </Tabs>
              <div className="w-full md:w-72">
                <Input label="Search" icon={<MagnifyingGlassIcon className="h-5 w-5" />} />
              </div>
            </div>
          </CardHeader>
          <Skeleton height={40} width={355} inline={true} style={{marginTop: 40, marginLeft:2}}/>
              <Skeleton height={30} width={320} style={{marginTop: 40, marginLeft:20}}/>
              <Skeleton count={8} height={30} width={320} style={{marginTop: 10, marginLeft:20}}/>
              
              
            </div>
            </ClinicLayout>
            </Media>
            </MediaContextProvider>
  {/* </div> */}
  </>
)
}
export default DoctorAvailabilityList;