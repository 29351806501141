import React from "react";
import Index from '../../index'
import SideBar from "../../comman/Sidebar/SideBar";
import ClinicHeader from "./ClinicHeader";
// import StatsCard from "../../comman/StatsCard/StatsCard";
import { useSelector } from "react-redux";
import StaffSideBar from "../../comman/Sidebar/StaffSidebar";


const ClinicLayout = ({children}) => {

    const loginType = useSelector((state) => state.login.type);

    return (
        <>
            <Index.Card className="flex flex-col h-screen">
                <Index.Card>
                    <ClinicHeader />
                </Index.Card>
                <Index.Card className="flex  flex-row ">
                    <Index.Card className="lg:w-[17vw] shadow-none w-[0vw]">
                        { loginType === "CLINIC" ? <SideBar /> : <StaffSideBar /> }
                    </Index.Card>
                    <Index.Card className="lg:w-[82vw] rounded-none w-[100vw]">
                        {children}
                    </Index.Card>
                </Index.Card>
            </Index.Card>
        </>
    )
}

export default ClinicLayout;