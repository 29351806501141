export const GET_STAFF_LIST_REQUEST = "GET_STAFF_LIST_REQUEST";
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const CLINIC_SERVER_ERROR = "CLINIC_SERVER_ERROR";
export const ADD_NEW_STAFF_REQUEST = "ADD_NEW_STAFF_REQUEST";
export const ADD_NEW_STAFF_SUCCESS = "ADD_NEW_STAFF_SUCCESS";
export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const UPDATE_STAFF_REQUEST = "UPDATE_STAFF_REQUEST"
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS"
export const GET_DOCTOR_LIST_REQUEST = "GET_DOCTOR_LIST_REQUEST";
export const GET_DOCTOR_LIST_SUCCESS = "GET_DOCTOR_LIST_SUCCESS";
export const ADD_NEW_DOCTOR_REQUEST = "ADD_NEW_DOCTOR_REQUEST";
export const ADD_NEW_DOCTOR_SUCCESS = "ADD_NEW_DOCTOR_SUCCESS";
export const UPDATE_DOCTOR_REQUEST = "UPDATE_DOCTOR_REQUEST";
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS";
export const GET_PATIENT_LIST_REQUEST = "GET_PATIENT_LIST_REQUEST";
export const GET_PATIENT_LIST_SUCCESS = "GET_PATIENT_LIST_SUCCESS";
export const GET_DOCTOR_AVAILABILITY_LIST_REQUEST = "GET_DOCTOR_AVAILABILITY_LIST_REQUEST";
export const GET_DOCTOR_AVAILABILITY_LIST_SUCCESS = "GET_DOCTOR_AVAILABILITY_LIST_SUCCESS";
export const ADD_NEW_DOCTOR_AVAILABILITY_REQUEST = "ADD_NEW_DOCTOR_AVAILABILITY_REQUEST";
export const ADD_NEW_DOCTOR_AVAILABILITY_SUCCESS = "ADD_NEW_DOCTOR_AVAILABILITY_SUCCESS";
export const UPDATE_DOCTOR_AVAILABILITY_REQUEST = "UPDATE_DOCTOR_AVAILABILITY_REQUEST";
export const UPDATE_DOCTOR_AVAILABILITY_SUCCESS = "UPDATE_DOCTOR_AVAILABILITY_SUCCESS";
export const DELETE_DOCTOR_AVAILABILITY_SUCCESS = "DELETE_DOCTOR_AVAILABILITY_SUCCESS";
export const GET_CLINIC_PROFILE_REQUEST = "GET_CLINIC_PROFILE_REQUEST";  // Get Clinic Profile
export const GET_CLINIC_PROFILE_SUCCESS = "GET_CLINIC_PROFILE_SUCCESS";
export const ADD_NEW_PATIENT_REQUEST = "ADD_NEW_PATIENT_REQUEST";
export const ADD_NEW_PATIENT_SUCCESS = "ADD_NEW_PATIENT_SUCCESS";
export const UPDATE_PATIENT_REQUEST = "UPDATE_PATIENT_REQUEST";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const GET_APPOINTMENT_LIST_REQUEST = "GET_APPOINTMENT_LIST_REQUEST";
export const GET_APPOINTMENT_LIST_SUCCESS = "GET_APPOINTMENT_LIST_SUCCESS";
export const ADD_NEW_APPOINTMENT_REQUEST = "ADD_NEW_APPOINTMENT_REQUEST";
export const ADD_NEW_APPOINTMENT_SUCCESS = "ADD_NEW_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_REQUEST = "UPDATE_APPOINTMENT_REQUEST";
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";
export const UPDATE_CLINIC_PROFILE_REQUEST = "UPDATE_CLINIC_PROFILE_REQUEST";
export const UPDATE_CLINIC_PROFILE_SUCCESS = "UPDATE_CLINIC_PROFILE_SUCCESS";
export const GET_MEDICINE_LIST_REQUEST = "GET_MEDICINE_LIST_REQUEST";
export const GET_MEDICINE_LIST_SUCCESS = "GET_MEDICINE_LIST_SUCCESS";
export const ADD_NEW_MEDICINE_REQUEST = "ADD_NEW_MEDICINE_REQUEST";
export const ADD_NEW_MEDICINE_SUCCESS = "ADD_NEW_MEDICINE_SUCCESS";
export const UPDATE_STAFF_PROFILE_REQUEST = "UPDATE_STAFF_PROFILE_REQUEST";
export const UPDATE_STAFF_PROFILE_SUCCESS = "UPDATE_STAFF_PROFILE_SUCCESS";
export const GET__NON_AVAILABILITY_REQUEST = "GET_NON_AVAILABILITY_REQUEST";
export const GET_NON_AVAILABILITY_LIST_SUCCESS = "GET_NON_AVAILABILITY_SUCCESS";
export const ADD_NON_AVAILABILITY_REQUEST = "ADD_NON_AVAILABILITY_REQUEST";
export const ADD_NON_AVAILABILITY_SUCCESS = "ADD_NON_AVAILABILITY_SUCCESS";
export const DELETE_NON_AVAILABILITY_REQUEST = "DELETE_NON_AVAILABILITY_REQUEST"; // DeleteNonAvailability
export const DELETE_NON_AVAILABILITY_SUCCESS = "DELETE_NON_AVAILABILITY_SUCCESS";
