import React, { useEffect, useState } from 'react'
// import DoctorsHeader from '../../components/Layout/Doctors/DoctorsHeader';
import ClinicLayout from '../../components/Layout/Clinic/ClinicLayout';
// import StatsCard from '../../components/comman/StatsCard/StatsCard';
// import animationData from './data.json'
// import Index from '../../components';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { createMedia } from "@artsy/fresnel"
import { CChart } from '@coreui/react-chartjs';
import axios from 'axios';
import {
    Card,
    CardHeader,
    Typography,
    CardBody,
  } from "@material-tailwind/react";
  import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
  import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
  import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
  import AirlineSeatIndividualSuiteOutlinedIcon from '@mui/icons-material/AirlineSeatIndividualSuiteOutlined';
  import { GetDashboardListAction } from '../../redux/Clinic/clinicActions';
import { useDispatch, useSelector } from 'react-redux';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})
const Dashboard = () => {
    const userType = useSelector(state => state.login.type);

    const dispatch = useDispatch()
    const accessToken = useSelector(state => state.login.accessToken)
    const dashboardList = useSelector(state => state.clinic.dashboardList)
    const [allpatient, setallpatient] = useState(null);
    console.log(dashboardList,"dashh")
    

    React.useEffect(() => {
      dispatch(GetDashboardListAction(accessToken))
    }, [])

    useEffect(() => {
      const fetchData = async () =>{
        try{
        const response2 = await axios.get('https://www.api.medi91.in/appointment/view-all/patient',{
          headers: {
            "Authorization":`Bearer ${accessToken}`
          }
          });
          setallpatient(response2.data.data.results);
        }
        catch(error){
          console.error('Error fetching data:', error);
        }
      }
      fetchData();
    },[]);

    

    if(allpatient != null){
      function getDayOfWeek(dateString) {
        const date = new Date(dateString);
        const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
        const dayOfWeekIndex = date.getDay();
      
        return daysOfWeek[dayOfWeekIndex];
      }
      var mon = 0
      var tue = 0
      var wed = 0
      var thu = 0
      var fri = 0
      var sat = 0
      var sun = 0

      for(let i = 0; i < allpatient.length; i++){
        // console.log("days ====== ",getDayOfWeek(allpatient[i].date_of_creation))
        if(getDayOfWeek(allpatient[i].date_of_creation) == 'Mon'){
          mon += 1;
        }
        if(getDayOfWeek(allpatient[i].date_of_creation) == 'Tues'){
          tue += 1;
        }
        if(getDayOfWeek(allpatient[i].date_of_creation) == 'Wed'){
          wed += 1;
        }
        if(getDayOfWeek(allpatient[i].date_of_creation) == 'Thur'){
          thu += 1;
        }
        if(getDayOfWeek(allpatient[i].date_of_creation) == 'Fri'){
          fri += 1;
        }
        if(getDayOfWeek(allpatient[i].date_of_creation) == 'Sat'){
          sat += 1;
        }
        if(getDayOfWeek(allpatient[i].date_of_creation) == 'Sun'){
          sun += 1;
        }
      }
      console.log("mon : ", mon, "tue : ", tue, "wed : ", wed, "thu : ", thu, "fri : ", fri, "sat : ", sat, "sun : ", sun)
  }
    if (dashboardList != null){
      // console.log("wait...")
    if (userType=='CLINIC'){

    return <>
    <MediaContextProvider>
    <Media greaterThanOrEqual="lg">
    <ClinicLayout>
        {/* {userType} */}
        <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 text-center">
              {/* <div> */}
                <Typography color="blue-gray" className="font-serif text-3xl">
                {userType} DASHBOARD
                </Typography>
            </div>
        </CardHeader>
        <CardBody className=' gap-4 -mt-4'>
          <Card className='flex flex-row shadow-lg bg-[#F9F9F9]'>
        {dashboardList && <>{dashboardList.map((dashboard, index) => {
                  return (
                    <>
            
            <Card className='w-3/12 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <AirlineSeatIndividualSuiteOutlinedIcon className=' ml-4 border rounded-full p-2 bg-[#b6976f] text-white' sx={{fontSize: { xs: 50, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent gap-10 shadow-none ml-4'>
                <Typography className="self-center lg:mt-2 lg:text-[22px]  font-serif">Total Patients</Typography>
                <Typography className="self-end lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_patients}</Typography>
                </Card>
                </Card>
            <Card className='w-3/12 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <AssignmentTurnedInOutlinedIcon className=' ml-4 border rounded-full p-2 bg-[#7dc47d] text-white' sx={{fontSize: { xs: 50, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent gap-10 shadow-none ml-4'>
                <Typography className="self-center lg:mt-2 lg:text-[22px]  font-serif">Total Appointments</Typography>
                <Typography className="self-end lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_appointments}</Typography>
                </Card>
            </Card>
            <Card className='w-3/12 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <LocalHospitalOutlinedIcon className=' ml-4 border rounded-full p-2 bg-[#618da5bb] text-white' sx={{fontSize: { xs: 50, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent gap-10 shadow-none ml-4'>
                <Typography className="self-center lg:mt-2 lg:text-[22px] font-serif">Total Doctors</Typography>
                <Typography className="self-end lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_doctors}</Typography>
                </Card>
            </Card>
            <Card className='w-3/12 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <PeopleAltOutlinedIcon className=' ml-4 border rounded-full p-2 bg-[#8e6acfd8] text-white' sx={{fontSize: { xs: 50, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent gap-10 shadow-none ml-4'> 
                <Typography className="self-center lg:mt-2 lg:text-[22px]  font-serif">Total Staff</Typography>  
                <Typography className=" lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_staff}</Typography>
                </Card>
            </Card>  
            </>
            );
          })} </>}
          </Card>
        </CardBody>
        <CardBody className='-mt-3 flex gap-4'>
        {dashboardList && <>{dashboardList.map((dashboard, index) => {
                  return (
                    <>
                    <Card className='shadow-lg w-[45vw] bg-[#F9F9F9] p-2'>
                    <Typography className='ml-2 text-black' variant='h5'>Clinic</Typography>
<CChart
  type="bar"
  height={120}
  className='font-serif text-xl p-2'
  data={{
    labels: ['Total Patients', 'Total Appointments', 'Total Doctors', 'Total Staff'],
    datasets: [
      {
        label: 'Dashboard Data',
        backgroundColor: [
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
        ],
        borderWidth: 1,
        data : [dashboard.total_patients,dashboard.total_appointments,dashboard.total_doctors,dashboard.total_staff],
      },
    ],
  }}
  labels="Dashboard Data"
  options={{
    scales: {
      x: {
        grid: {
          display:false,
        },
      },
    },
  }}
/></Card>
</>
  );
})} </>}
<Card className='shadow-lg w-[45vw] bg-[#F9F9F9] p-2'>
  <Typography className='ml-2 text-black' variant='h5'>Patients</Typography>
<CChart
  type="bar"
  height={120}
  className='font-serif text-xl p-2'
  data={{
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [
      {
        label: 'Dashboard Data',
        backgroundColor: [
          'rgba(27, 122, 67, 0.2)',
          'rgba(43, 133, 255, 0.2)',
          'rgba(254, 118, 75, 0.2)',
          'rgba(255, 98, 112, 0.2)',
          'rgba(0, 138, 215, 0.2)',
          'rgba(215, 103, 0, 0.2)',
          'rgba(0, 99, 215, 0.2)',
        ],
        borderColor: [
          'rgb(27, 122, 67)',
          'rgb(43, 133, 255)',
          'rgb(254, 118, 75)',
          'rgb(255, 98, 112)',
          'rgb(0, 138, 215)',
          'rgb(215, 103, 0)',
          'rgb(0, 99, 215)',
        ],
        borderWidth: 1,
        borderRadius: 5,
        barPercentage: 0.2,
        data : [mon,tue,wed,thu,fri,sat,sun],
      },
    ],
  }}
  labels="Dashboard Data"
  options={{
    scales: {
      x: {
        grid: {
          display:false,
        },
      },
    },
  }}
/>
</Card>
</CardBody>
        </Card>
    </ClinicLayout>
    </Media>
    <Media at='sm'>
    <ClinicLayout>
        {/* {userType} */}
        <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 text-center">
              {/* <div> */}
                <Typography color="blue-gray" className="font-serif text-3xl">
                {userType} DASHBOARD
                </Typography>
            </div>
        </CardHeader>
        <CardBody className='p-2 gap-4 -mt-4'>
          <Card className='gap-4 shadow-none bg-transparent'>
        {dashboardList && <>{dashboardList.map((dashboard, index) => {
                  return (
                    <>
            <Card className='flex flex-row shadow-lg bg-[#F9F9F9]'>
            <Card className='w-3/6 h-28 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <AirlineSeatIndividualSuiteOutlinedIcon className='ml-2 lg:ml-4 border rounded-full p-2 bg-[#b6976f] text-white' sx={{fontSize: { xs: 60, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent shadow-none ml-4'>
                <Typography className="self-center lg:mt-2 lg:text-[22px]  font-serif">Total Patients</Typography>
                <Typography className="self-end lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_patients}</Typography>
                </Card>
                </Card>
            <Card className='w-3/6 h-28 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <AssignmentTurnedInOutlinedIcon className='ml-2 lg:ml-4 border rounded-full p-2 bg-[#7dc47d] text-white' sx={{fontSize: { xs: 60, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent shadow-none ml-4'>
                <Typography className="self-center lg:mt-2 lg:text-[22px]  font-serif">Total Appointments</Typography>
                <Typography className="self-end lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_appointments}</Typography>
                </Card>
            </Card>
            </Card>
            <Card className='flex flex-row shadow-lg bg-[#F9F9F9]'>
            <Card className='w-3/6 h-28 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <LocalHospitalOutlinedIcon className='ml-2 lg:ml-4 border rounded-full p-2 bg-[#618da5bb] text-white' sx={{fontSize: { xs: 60, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent shadow-none ml-4'>
                <Typography className="self-center lg:mt-2 lg:text-[22px] font-serif">Total Doctors</Typography>
                <Typography className="self-end lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_doctors}</Typography>
                </Card>
            </Card>
            <Card className='w-3/6 h-28 lg:h-32 md:h-24 shadow-none bg-transparent flex flex-row items-center' class="flex-1 " color=''>
            
                <PeopleAltOutlinedIcon className='ml-2 lg:ml-4 border rounded-full p-2 bg-[#8e6acfd8] text-white' sx={{fontSize: { xs: 60, sm: 40, md: 100, lg: 80 } }}/>
                <Card className='grid bg-transparent shadow-none ml-4'> 
                <Typography className="self-center lg:mt-2 lg:text-[22px]  font-serif">Total Staff</Typography>  
                <Typography className=" lg:mr-10 lg:-mt-13 lg:text-[40px] md:text-[45px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_staff}</Typography>
                </Card>
            </Card>  
            </Card>
            </>
            );
          })} </>}
          </Card>
        </CardBody>
        <CardBody className=' gap-4 p-2'>
        {dashboardList && <>{dashboardList.map((dashboard, index) => {
                  return (
                    <>
                    <Card className='shadow-lg w-[95.5vw] bg-[#F9F9F9] p-2'>
                    <Typography className='ml-2 text-black' variant='h5'>Clinic</Typography>
<CChart
  type="bar"
  height={160}
  className='font-serif text-xl p-2'
  data={{
    labels: ['Total Patients', 'Total Appointments', 'Total Doctors', 'Total Staff'],
    datasets: [
      {
        label: 'Dashboard Data',
        backgroundColor: [
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
        ],
        borderWidth: 1,
        data : [dashboard.total_patients,dashboard.total_appointments,dashboard.total_doctors,dashboard.total_staff],
      },
    ],
  }}
  labels="Dashboard Data"
  options={{
    scales: {
      x: {
        grid: {
          display:false,
        },
      },
    },
  }}
/></Card>
</>
  );
})} </>}
<Card className='shadow-lg w-[95.5vw] bg-[#F9F9F9] p-2 mt-2'>
  <Typography className='ml-2 text-black' variant='h5'>Patients</Typography>
<CChart
  type="bar"
  height={150}
  className='font-serif text-xl p-2'
  data={{
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [
      {
        label: 'Dashboard Data',
        backgroundColor: [
          'rgba(27, 122, 67, 0.2)',
          'rgba(43, 133, 255, 0.2)',
          'rgba(254, 118, 75, 0.2)',
          'rgba(255, 98, 112, 0.2)',
          'rgba(0, 138, 215, 0.2)',
          'rgba(215, 103, 0, 0.2)',
          'rgba(0, 99, 215, 0.2)',
        ],
        borderColor: [
          'rgb(27, 122, 67)',
          'rgb(43, 133, 255)',
          'rgb(254, 118, 75)',
          'rgb(255, 98, 112)',
          'rgb(0, 138, 215)',
          'rgb(215, 103, 0)',
          'rgb(0, 99, 215)',
        ],
        borderWidth: 1,
        borderRadius: 5,
        barPercentage: 0.2,
        data : [mon,tue,wed,thu,fri,sat,sun],
      },
    ],
  }}
  labels="Dashboard Data"
  options={{
    scales: {
      x: {
        grid: {
          display:false,
        },
      },
    },
  }}
/>
</Card>
</CardBody>
        </Card>
    </ClinicLayout>
    </Media>
    </MediaContextProvider>
    </>;
    
    }
    return <>
    <ClinicLayout>
        {/* {userType} */}
        <Card className="h-full w-full rounded-none">
        <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 text-center">
              {/* <div> */}
                <Typography color="blue-gray" className="font-serif text-3xl">
                {userType} DASHBOARD
                </Typography>
            </div>
        </CardHeader>
        <CardBody className='flex gap-4 -mt-4'>
            {dashboardList && <>{dashboardList.map((dashboard, index) => {
                  return (
                    <>
            <Card className='w-4/12 h-32 shadow-md bg-[#b6e1f8bb]' class="flex-1 " color=''>
            <Typography className="self-center lg:mt-2 lg:text-[22px] font-semibold font-serif">Total Patients</Typography>
                <AirlineSeatIndividualSuiteOutlinedIcon className='self-start mt-3 ml-12 border rounded-full p-2 bg-white text-[#618da5bb]' sx={{fontSize: { xs: 50, sm: 40, md: 100, lg: 55 } }}/>
                <Typography className="self-end lg:mr-14 lg:-mt-13 lg:text-[30px] md:text-[35px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_patients}</Typography>
                </Card>
            <Card className='w-4/12 h-32 shadow-md bg-[#FFEDD5]' class="flex-1 " color=''>
                <Typography className="self-center lg:mt-2 lg:text-[22px] font-semibold font-serif">Total Appointments</Typography>
                <AssignmentTurnedInOutlinedIcon className='self-start mt-3 ml-12 border rounded-full p-2 bg-white text-[#b6976f]' sx={{fontSize: { xs: 50, sm: 40, md: 100, lg: 55 } }}/>
                <Typography className="self-end lg:mr-14 lg:-mt-13 lg:text-[30px] md:text-[35px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_appointments}</Typography>
            </Card>
            <Card className='w-4/12 h-32 shadow-md bg-[#D3EDD3]' class="flex-1 " color=''>
            <Typography className="self-center lg:mt-2 lg:text-[22px] font-semibold font-serif">Total Doctors</Typography>
                <LocalHospitalOutlinedIcon className='self-start mt-3 ml-12 border rounded-full p-2 bg-white text-[#7dc47d]' sx={{fontSize: { xs: 50, sm: 40, md: 100, lg: 55 } }}/>   
                <Typography className="self-end lg:mr-14 lg:-mt-13 lg:text-[30px] md:text-[35px] md:mr-6 md:-mt-12 font-semibold">{dashboard.total_doctors}</Typography>
            </Card> 
            </>
            );
          })} </>}
        </CardBody>
        <CardBody className='-mt-4'>
        {dashboardList && <>{dashboardList.map((dashboard, index) => {
                  return (
                    <>
<CChart
  type="bar"
  height={120}
  data={{
    labels: ['Total Patients', 'Total Appointments', 'Total Doctors'],
    datasets: [
      {
        label: 'Dashboard Data',
        backgroundColor: [
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
        ],
        borderWidth: 1,
        barPercentage: 0.8,
        data : [dashboard.total_patients,dashboard.total_appointments,dashboard.total_doctors],
      },
    ],
  }}
  labels="Dashboard Data"
  options={{
    scales: {
      x: {
        grid: {
          display:false,
        },
      },
    },
  }}
/>
</>
  );
})} </>}

</CardBody>
        </Card>
    </ClinicLayout>
    </>;
  }
    return (
      <>
        <MediaContextProvider>
          <Media greaterThanOrEqual='lg'>
            <ClinicLayout>
            <div class="flex-1" className='h-full w-full float-left'>
              
              <Skeleton height={30} width={350} style={{marginTop: 10, marginLeft:450}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 10, marginLeft:40}}/> */}
              <Skeleton height={120} width={284} inline={true} style={{marginTop: 40, marginLeft:30}}/>
              <Skeleton count={3} height={120} width={284} inline={true} style={{marginTop: 40, marginLeft:20}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 0, marginLeft:40}}/> */}
              <Skeleton height={300} width={580} inline={true} style={{marginTop: 40, marginLeft:30}}/>
              <Skeleton height={300} width={580} inline={true} style={{marginTop: 40, marginLeft:30}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 0, marginLeft:40}}/> */}
              
            </div>
            </ClinicLayout>
            </Media>
            <Media at='sm'>
            <ClinicLayout>
            <div class="flex-1" className='h-full w-full float-left'>
              
              <Skeleton height={30} width={250} style={{marginTop: 10, marginLeft:50}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 10, marginLeft:40}}/> */}
              {/* <Skeleton height={120} width={284} inline={true} style={{marginTop: 40, marginLeft:30}}/> */}
              <Skeleton count={2} height={120} width={320} inline={true} style={{marginTop: 30, marginLeft:20}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 0, marginLeft:40}}/> */}
              <Skeleton height={200} width={320} inline={true} style={{marginTop: 30, marginLeft:20}}/>
              <Skeleton height={200} width={320} inline={true} style={{marginTop: 30, marginLeft:20}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 0, marginLeft:40}}/> */}
              
            </div>
            </ClinicLayout>
            </Media>
            {/* </div> */}
            </MediaContextProvider>
      </>
    )
}


export default Dashboard;
