import axios from 'axios'

let baseURL = 'https://www.api.medi91.in/'

const DataService = axios.create({
    baseURL: baseURL
})



export default DataService;
