import React from 'react'
import Index from '../..'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { createMedia } from "@artsy/fresnel"
import { useDispatch, useSelector } from 'react-redux';
import {
    Drawer,
    Button,
    Typography,
    IconButton,
  } from "@material-tailwind/react";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  })


const DoctorSideBar = () => {

    const [open, setOpen] = React.useState(false);
    const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const userDetail = useSelector(state => state.login.userDetail)

    const links = [
        {
            title: "Doctor Dashboard",
            icon: <Index.DashboardOutlined />,
            navigate: '/doctor/doctordashboard',
        },
        {
            title: "Appointments",
            icon: <Index.CalendarMonthOutlinedIcon />,
            navigate: '/doctor/self/appointment',
        },
        {
            title: "Patients",
            icon: <Index.AccessibleIcon />,
            navigate: '/doctor/self/patient/list',
        },
 
    ]
    
    return (
        <>
        <MediaContextProvider>
<Media greaterThanOrEqual="lg">
            <Index.Card className="h-[calc(100vh-2rem)] w-full max-w-[16rem] p-4 shadow-xxl bg-[#151718] rounded-none">
                <div className="mb-2 p-4">
                    <Index.Typography variant="h5" color="blue-gray">

                    </Index.Typography>
                </div>
                <Index.List>
                    {links.map((link) => {
                        return (
                            <> 
                            <NavLink to={link.navigate}>
                                <Index.ListItem className='text-xl font-serif text-[#8D8585] hover:text-[#FFFFFF] hover:bg-transparent rounded-none active:bg-transparent focus:bg-transparent focus-visible:border-l-4 focus:text-[#FFFFFF] active:text-[#FFFFFF] highlight'>
                                    <Index.ListItemPrefix>
                                        {/* <Index.DashboardIcon /> */}
                                        {link.icon}
                                    </Index.ListItemPrefix>
                                    {link.title}
                                </Index.ListItem>
                            </NavLink>
                            </>
                        )
                    })}
                </Index.List>
            </Index.Card>
            </Media>
            <Media at='sm'>
            <Index.Card className="h-[calc(100vh-2rem)] w-full max-w-[16rem] shadow-xxl bg-[#151718] rounded-none">
                {/* <div className="mb-2 p-4">
                    <Index.Typography variant="h5" color="blue-gray">

                    </Index.Typography>
                </div> */}
                <Index.Card className='bg-[#172B4D] -mt-[87px] flex flex-row items-center rounded-none shadow-none w-[16rem] h-[87px] border-white border-b-[1px] px-2 truncate'>
            <Button className='w-fit h-fit bg-transparent mr-2 shadow-none' onClick={openDrawer}><MenuIcon/></Button>
                    <Index.Typography className='text-4xl text-white font-bold font-serif  py-6'>
                        Dr. {userDetail.first_Name}
                    </Index.Typography>
                </Index.Card>
                <Drawer open={open} onClose={closeDrawer} className="w-fit bg-transparent">
                <div className="mb-6 h-[63px] ml-4 flex items-center justify-between">
          {/* <Typography variant="h3" color="blue-gray">
          {userDetail.clinic_Name}
          </Typography> */}
          <IconButton className='mt-6 ml-3' variant="text" color="blue-gray" onClick={closeDrawer}>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg> */}
          </IconButton>
        </div>
        <Index.Card className='h-[calc(100vh-2rem)] w-[16rem] p-4 shadow-xxl bg-[#151718] rounded-none'>
                <Index.List>
                    {links.map((link) => {
                        return (
                            <> 
                            <NavLink to={link.navigate}>
                                <Index.ListItem className='text-xl font-serif text-[#8D8585] hover:text-[#FFFFFF] hover:bg-transparent rounded-none active:bg-transparent focus:bg-transparent focus-visible:border-l-4 focus:text-[#FFFFFF] active:text-[#FFFFFF] highlight'>
                                    <Index.ListItemPrefix>
                                        {/* <Index.DashboardIcon /> */}
                                        {link.icon}
                                    </Index.ListItemPrefix>
                                    {link.title}
                                </Index.ListItem>
                            </NavLink>
                            </>
                        )
                    })}
                </Index.List>
                </Index.Card>
                </Drawer>
            </Index.Card>
            </Media>
            </MediaContextProvider></>
    )
}

export default DoctorSideBar;
