import React from 'react'
import Index from '../..'
// import { Link } from 'react-router-dom'
import { NavLink, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import { createMedia } from "@artsy/fresnel"
import { useDispatch, useSelector } from 'react-redux';
import {
    Drawer,
    Button,
    Typography,
    IconButton,
  } from "@material-tailwind/react";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  })
const SideBar = () => {
    const userDetail = useSelector(state => state.login.userDetail)
    const [open, setOpen] = React.useState(false);
 
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
    const links = [
        {
            title: "Dashboard",
            icon: <Index.DashboardOutlined />,
            navigate: '/clinic/dashboard',
        },
        {
            title: "Staff",
            icon: <Index.PeopleAltOutlined />,
            navigate: '/clinic/staff/list',
        },
        {
            title: "Patient",
            icon: <Index.AccessibilityNewIcon />,
            navigate: '/clinic/patient/list'
        },
        {
            title: "Doctors",
            // icon: <Index.AccessibilityNewIcon />,
            icon: <Index.MedicalServicesOutlined />,
            navigate: '/clinic/doctors/list'
        },
        {
            title: "Disable Slots",
            // icon: <Index.AccountCircleOutlinedIcon />,
            icon: <Index.AlarmOffIcon/>,
            navigate: '/clinic/Doctor_non_availability/list'
        },
        {
            title: "Appointments",
            // icon: <Index.AccountCircleOutlinedIcon />,
            icon: <Index.ContactPhoneOutlinedIcon />,
            navigate: '/clinic/appointment/list'
        },
        {
            title: "Medicine",
            icon: <Index.MedicationLiquidIcon />,
            navigate: '/clinic/medicine/list'
        },
    ]
const navigate = useNavigate()
    return (
        <>
<MediaContextProvider>
<Media greaterThanOrEqual="lg">
            <Index.Card className="h-[calc(100vh-2rem)] w-full max-w-[16rem] p-4 shadow-xxl bg-[#151718] rounded-none">
                <div className="mb-2 pl-5">
                    <Index.Typography variant="h5" color="blue-gray">
                     {/* <Index.IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    // sx={{ mr: 2 }}
                    >
                    <Index.MenuIcon />
                    </Index.IconButton> */}
                    </Index.Typography>
                </div>
                <Index.List>
                    {links.map((link) => {
                        return (
                            <>
                                <NavLink to={link.navigate}>
                                    <Index.ListItem className='font-serif text-xl text-[#8D8585] hover:text-[#FFFFFF] hover:bg-transparent rounded-none active:bg-transparent focus:bg-transparent focus-visible:border-l-4 focus:text-[#FFFFFF] active:text-[#FFFFFF] highlight'>
                                        <Index.ListItemPrefix>
                                            {/* <Index.DashboardIcon /> */}
                                            {link.icon}
                                        </Index.ListItemPrefix>
                                        {link.title}
                                    </Index.ListItem>
                                </NavLink>
                            </>
                        )
                    })}
                </Index.List>
                <Index.Card className='h-[14vw] w-[14.5vw] mt-4 flex items-center justify-center'>
                    <Index.BusinessCenterIcon className='text-black' sx={{fontSize:50}}/>
                    <Index.Typography variant='h5' className='text-black mt-2'>Appointment</Index.Typography>
                    <Index.Typography>Would you like to make a</Index.Typography>
                    <Index.Typography>new appointment to ?</Index.Typography>
                    <Index.Button className='bg-black mt-2' onClick={()=>{navigate('/clinic/appointment/list')}}>Book an App</Index.Button>
                </Index.Card>
            </Index.Card>
            </Media>
            <Media at="sm">
            <Index.Card className='bg-[#172B4D] -mt-[87px] flex flex-row items-center rounded-none shadow-none w-[16rem] h-[87px] border-white border-b-[1px] px-2 truncate'>
            <Button className='w-fit h-fit bg-transparent mr-2 shadow-none' onClick={openDrawer}><MenuIcon/></Button>
                    <Index.Typography className='text-4xl text-white font-bold font-serif  py-6'>
                        {userDetail.clinic_Name}
                    </Index.Typography>
                </Index.Card>
                <Drawer open={open} onClose={closeDrawer} className="w-fit bg-transparent">
                <div className="mb-6 h-[63px] ml-4 flex items-center justify-between">
          {/* <Typography variant="h3" color="blue-gray">
          {userDetail.clinic_Name}
          </Typography> */}
          <IconButton className='mt-6 ml-3' variant="text" color="blue-gray" onClick={closeDrawer}>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg> */}
          </IconButton>
        </div>
            <Index.Card className="h-[calc(100vh-2rem)] w-[16rem] p-4 shadow-xxl bg-[#151718] rounded-none">
                <div className="mb-2 pl-5">
                    <Index.Typography variant="h5" color="blue-gray">
                     {/* <Index.IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    // sx={{ mr: 2 }}
                    >
                    <Index.MenuIcon />
                    </Index.IconButton> */}
                    </Index.Typography>
                </div>
                <Index.List>
                    {links.map((link) => {
                        return (
                            <>
                                <NavLink to={link.navigate}>
                                    <Index.ListItem className='font-serif text-xl text-[#8D8585] hover:text-[#FFFFFF] hover:bg-transparent rounded-none active:bg-transparent focus:bg-transparent focus-visible:border-l-4 focus:text-[#FFFFFF] active:text-[#FFFFFF] highlight'>
                                        <Index.ListItemPrefix>
                                            {/* <Index.DashboardIcon /> */}
                                            {link.icon}
                                        </Index.ListItemPrefix>
                                        {link.title}
                                    </Index.ListItem>
                                </NavLink>
                            </>
                        )
                    })}
                </Index.List>
                <Index.Card className='lg:h-[14vw] mt-4 p-2 flex items-center justify-center'>
                    <Index.BusinessCenterIcon className='text-black' sx={{fontSize:50}}/>
                    <Index.Typography variant='h5' className='text-black mt-2'>Appointment</Index.Typography>
                    <Index.Typography>Would you like to make a</Index.Typography>
                    <Index.Typography>new appointment to ?</Index.Typography>
                    <Index.Button className='bg-black mt-2' onClick={()=>{navigate('/clinic/appointment/list')}}>Book an App</Index.Button>
                </Index.Card>
            </Index.Card>
            </Drawer>
            </Media>
            </MediaContextProvider>
            </>
    )
}

export default SideBar;
