import React, {useEffect, useState} from 'react'
// import DoctorHeader from '../../components/Layout/Doctors/DoctorsHeader';
import DoctorLayout from '../../components/Layout/Doctors/DoctorLayout';
// import StatsCard from '../../components/comman/StatsCard/StatsCard';
// import Index from '../../components';
// import animationData from './data.json'

// import Index from '../../components';
import Skeleton from 'react-loading-skeleton'
import axios, { all } from 'axios';
import 'react-loading-skeleton/dist/skeleton.css'
import { CChart } from '@coreui/react-chartjs';
import { createMedia } from "@artsy/fresnel"
import {
    Card,
    CardHeader,
    Typography,
    CardBody,
    CardFooter,
  } from "@material-tailwind/react";
  // import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
  // import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
  import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
  import AirlineSeatIndividualSuiteOutlinedIcon from '@mui/icons-material/AirlineSeatIndividualSuiteOutlined';
  import { useSelector } from 'react-redux';

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192,
    },
  })

const DoctorDashboard = () => {
    const userType = useSelector(state => state.login.type);
    const token = useSelector(state => state.login.accessToken);
    // const doctorDashboard = useSelector(state => state.doctor.doctorDashboard)
    const [alldashboard, setalldashboard] = useState(null);
    const [allpatient, setallpatient] = useState(); 
    const [allappointments,setallappointments] =  useState(null);

    // const [dates,setdates]=useState([]);
    // console.log(dates)

    console.log(allpatient,"patient")
  // React.useEffect(() => {
  //   dispatch(GetDoctorDashboardDataAction(accessToken))
  //   // console.log(a,"xyz")
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch dashboard API
        const response1 = await axios.get('https://www.api.medi91.in/appointment/doctor/deshbord-data',{
          headers: {
            "Authorization":`Bearer ${token}`
          }
        });
        setalldashboard(response1.data.data);

        // Fetch patient API
        const response2 = await axios.get('https://www.api.medi91.in/appointment/view/doctor-self/patient',{
          headers: {
            "Authorization":`Bearer ${token}`
          }
          });
        setallpatient(response2.data.data);

        // Fetch allappointment api
        const response3 = await axios.get('https://www.api.medi91.in/appointment/view/doctor-self/appointments',{
          headers: {
            "Authorization":`Bearer ${token}`
          }
        });
        setallappointments(response3.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); 
  }, []);


  //function to calculate month wise appointment
  const getMonthlyAppointmentCount = () => {
    const counts = {};
    {allappointments && <>{allappointments.map((appoint,ind) => {
      const date = new Date(appoint.date_of_appointment);
      const month = date.getMonth() + 1; 
  
      if (counts[month]) {
        counts[month] += 1;
      } else {
        counts[month] = 1;
      }
    })}</>}
    // Fill in zero values for months without patients
    for (let month = 1; month <= 12; month++) {
    if (!counts[month]) {
      counts[month] = 0;
    }
  }
  
    return counts;
  };
  const monthlyAppointmentCount = getMonthlyAppointmentCount();
  console.log(monthlyAppointmentCount,"appointment list")

  //function to calculate month wise patient
  const getMonthlyPatientCount = () => {
    const count = {};
    {allpatient && <>{allpatient.map((pat,ind) => {
    const date = new Date(pat.date_of_creation);
      const month = date.getMonth() + 1; 
  
      if (count[month]) {
        count[month] += 1;
      } else {
        count[month] = 1;
      }
    
    })}</>}
    // Fill in zero values for months without patients
    for (let month = 1; month <= 12; month++) {
    if (!count[month]) {
      count[month] = 0;
    }
  }
    return count;
  }
  const monthlyPatientCount = getMonthlyPatientCount();
  console.log(monthlyPatientCount,"patient list")
  
  if(allpatient != null){
    function getDayOfWeek(dateString) {
      const date = new Date(dateString);
      const daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
      const dayOfWeekIndex = date.getDay();
    
      return daysOfWeek[dayOfWeekIndex];
    }
    var mon = 0
    var tue = 0
    var wed = 0
    var thu = 0
    var fri = 0
    var sat = 0
    var sun = 0

    for(let i = 0; i < allpatient.length; i++){
      // console.log("days ====== ",getDayOfWeek(allpatient[i].date_of_creation))
      if(getDayOfWeek(allpatient[i].date_of_creation) == 'Mon'){
        mon += 1;
      }
      if(getDayOfWeek(allpatient[i].date_of_creation) == 'Tues'){
        tue += 1;
      }
      if(getDayOfWeek(allpatient[i].date_of_creation) == 'Wed'){
        wed += 1;
      }
      if(getDayOfWeek(allpatient[i].date_of_creation) == 'Thur'){
        thu += 1;
      }
      if(getDayOfWeek(allpatient[i].date_of_creation) == 'Fri'){
        fri += 1;
      }
      if(getDayOfWeek(allpatient[i].date_of_creation) == 'Sat'){
        sat += 1;
      }
      if(getDayOfWeek(allpatient[i].date_of_creation) == 'Sun'){
        sun += 1;
      }
    }
    console.log("mon : ", mon, "tue : ", tue, "wed : ", wed, "thu : ", thu, "fri : ", fri, "sat : ", sat, "sun : ", sun)
}

    if (alldashboard != null){
      
  
      return <>
      <MediaContextProvider>
    <Media greaterThanOrEqual="lg">
      <DoctorLayout>
          {/* {userType} */}
          <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
              <div className="mb-8 text-center">
                <div>
                  <Typography variant="" color="blue-gray" className='font-serif text-3xl' > 
                  {userType} DASHBOARD
                  </Typography>
                </div>
              </div>
          </CardHeader>
          <CardBody className=' gap-20 -mt-4'>
          <Card className='flex flex-row shadow-lg bg-[#F9F9F9]'>
              {alldashboard && <>{alldashboard.map((dashboard,index)=> {
                return(
                  <>
                  
                  <Card className='w-6/12 h-40  bg-transparent shadow-none flex flex-row items-center' class="flex-1" color=''>
                  <div class="flex justify-between ...">
                  <AirlineSeatIndividualSuiteOutlinedIcon className='border rounded-full self-center bg-[#4fa16c] p-4 ml-5 text-white' sx={{ fontSize: 100 }}/>
                  <Card className='grid bg-transparent  shadow-none ml-8'>
                  <Typography className=" text-[30px] mr-12 self-center font-serif font-semibold">Total Patient</Typography>
                  <Typography className="  mr-4 text-[45px] font-semibold self-center">{dashboard.total_No_of_patient_of_doctor}</Typography>
                  </Card>
                  </div>
                  </Card>
                  <Card className='w-6/12 h-40  bg-transparent shadow-none flex flex-row items-center' class="flex-1 " color=''>
                  <div class="flex justify-between ...">
                  <AssignmentTurnedInOutlinedIcon className=' ml-5 border rounded-full self-center bg-[#618da5bb] p-4 text-white' sx={{ fontSize: 100 }}/>
                  <Card className='grid bg-transparent  shadow-none ml-8'>
                  <Typography className=" text-[30px] mr-12 self-center font-serif font-semibold ">Total Appointments</Typography>
                  <Typography className="  mr-4 text-[45px] font-semibold self-center">{dashboard.Doctor_total_appointments}</Typography>
                  </Card>
                  </div>
                  </Card>
                  </>
                )
              })}</>}
              </Card>
          </CardBody>
          <CardBody className='-mt-4 flex gap-4'>
          <Card className='shadow-lg w-[45vw] bg-[#F9F9F9] p-2'>
          <Typography className='ml-2 text-black' variant='h5'>Patients</Typography>
          <CChart 
    type="line" 
    className='font-serif text-xl p-2'
    data={{
      labels: ["January", "February", "March", "April", "May", "June", "July", "August","September","October","November","December"],
      // labels: Object.keys(monthlyAppointmentCount,monthlyPatientCount),
      datasets: [
        {
          label: "Patient dataset",
          backgroundColor: "Green",
          borderColor: "DarkSeaGreen",
          pointBackgroundColor: "Green",
          pointBorderColor: "Green",
          data: Object.values(monthlyPatientCount),
          // data :[2,5,6,9,0,6],
          pointBoardWidth: "10",
          // tension:0.5
        },
        {
          label: "Appointment dataset",
          backgroundColor: "blue",
          borderColor: "SkyBlue",
          pointBackgroundColor: "blue",
          pointBorderColor: "DeepSkyBlue",
          data: Object.values(monthlyAppointmentCount),
          pointBoardWidth: "10",
          // tension:0.5
        },
      ],
    }}
  />
  </Card>
  <Card className='shadow-lg w-[45vw] bg-[#F9F9F9] p-2'>
  <Typography className='ml-2 text-black' variant='h5'>Patients</Typography>
<CChart
  type="bar"
  
  className='font-serif text-xl p-2'
  data={{
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [
      {
        label: 'Dashboard Data',
        backgroundColor: [
          'rgba(27, 122, 67, 0.2)',
          'rgba(43, 133, 255, 0.2)',
          'rgba(254, 118, 75, 0.2)',
          'rgba(255, 98, 112, 0.2)',
          'rgba(0, 138, 215, 0.2)',
          'rgba(215, 103, 0, 0.2)',
          'rgba(0, 99, 215, 0.2)',
        ],
        borderColor: [
          'rgb(27, 122, 67)',
          'rgb(43, 133, 255)',
          'rgb(254, 118, 75)',
          'rgb(255, 98, 112)',
          'rgb(0, 138, 215)',
          'rgb(215, 103, 0)',
          'rgb(0, 99, 215)',
        ],
        borderWidth: 1,
        borderRadius: 5,
        barPercentage: 0.2,
        data : [mon,tue,wed,thu,fri,sat,sun],
      },
    ],
  }}
  labels="Dashboard Data"
  options={{
    scales: {
      x: {
        grid: {
          display:false,
        },
      },
    },
  }}
/>
</Card>
  </CardBody>
  <CardFooter>
  </CardFooter>
          </Card>
      </DoctorLayout>
      </Media>
      <Media at='sm'>
      <DoctorLayout>
          {/* {userType} */}
          <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
              <div className="mb-8 text-center">
                <div>
                  <Typography variant="" color="blue-gray" className='font-serif text-3xl' > 
                  {userType} DASHBOARD
                  </Typography>
                </div>
              </div>
          </CardHeader>
          <CardBody className=' gap-20 -mt-4 p-2'>
          <Card className=' shadow-none bg-transparent'>
              {alldashboard && <>{alldashboard.map((dashboard,index)=> {
                return(
                  <>
                  
                  <Card className='w-6/3 h-40  bg-[#F9F9F9] shadow-none flex flex-row items-center' class="flex-1" color=''>
                  <div class="flex justify-between ...">
                  <AirlineSeatIndividualSuiteOutlinedIcon className='border rounded-full self-center bg-[#4fa16c] p-4 ml-5 text-white' sx={{ fontSize: 100 }}/>
                  <Card className='grid bg-transparent w-[50vw] shadow-none ml-8'>
                  <Typography className=" text-[30px]  self-center font-serif font-semibold">Total Patient</Typography>
                  <Typography className="  mr-4 text-[45px] font-semibold self-center">{dashboard.total_No_of_patient_of_doctor}</Typography>
                  </Card>
                  </div>
                  </Card>
                  <Card className='w-6/3 mt-4 h-40  bg-[#F9F9F9] shadow-none flex flex-row items-center' class="flex-1 " color=''>
                  <div class="flex justify-between ...">
                  <AssignmentTurnedInOutlinedIcon className=' ml-5 border rounded-full self-center bg-[#618da5bb] p-4 text-white' sx={{ fontSize: 100 }}/>
                  <Card className='grid bg-transparent w-[50vw] shadow-none ml-8'>
                  <Typography className=" text-[30px]  self-center font-serif font-semibold ">Total Appointments</Typography>
                  <Typography className="  mr-4 text-[45px] font-semibold self-center">{dashboard.Doctor_total_appointments}</Typography>
                  </Card>
                  </div>
                  </Card>
                  </>
                )
              })}</>}
              </Card>
          </CardBody>
          <CardBody className=' gap-4 p-2'>
          <Card className='shadow-lg w-[96vw] bg-[#F9F9F9] p-2'>
          <Typography className='ml-2 text-black' variant='h5'>Patients</Typography>
          <CChart 
    type="line" 
    height={200}
    className='font-serif text-xl p-2'
    data={{
      labels: ["January", "February", "March", "April", "May", "June", "July", "August","September","October","November","December"],
      // labels: Object.keys(monthlyAppointmentCount,monthlyPatientCount),
      datasets: [
        {
          label: "Patient dataset",
          backgroundColor: "Green",
          borderColor: "DarkSeaGreen",
          pointBackgroundColor: "Green",
          pointBorderColor: "Green",
          data: Object.values(monthlyPatientCount),
          // data :[2,5,6,9,0,6],
          pointBoardWidth: "10",
          // tension:0.5
        },
        {
          label: "Appointment dataset",
          backgroundColor: "blue",
          borderColor: "SkyBlue",
          pointBackgroundColor: "blue",
          pointBorderColor: "DeepSkyBlue",
          data: Object.values(monthlyAppointmentCount),
          pointBoardWidth: "10",
          // tension:0.5
        },
      ],
    }}
  />
  </Card>
  <Card className='shadow-lg mt-2 w-[96vw] bg-[#F9F9F9] p-2'>
  <Typography className='ml-2 text-black' variant='h5'>Patients</Typography>
<CChart
  type="bar"
  
  className='font-serif text-xl p-2'
  data={{
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [
      {
        label: 'Dashboard Data',
        backgroundColor: [
          'rgba(27, 122, 67, 0.2)',
          'rgba(43, 133, 255, 0.2)',
          'rgba(254, 118, 75, 0.2)',
          'rgba(255, 98, 112, 0.2)',
          'rgba(0, 138, 215, 0.2)',
          'rgba(215, 103, 0, 0.2)',
          'rgba(0, 99, 215, 0.2)',
        ],
        borderColor: [
          'rgb(27, 122, 67)',
          'rgb(43, 133, 255)',
          'rgb(254, 118, 75)',
          'rgb(255, 98, 112)',
          'rgb(0, 138, 215)',
          'rgb(215, 103, 0)',
          'rgb(0, 99, 215)',
        ],
        borderWidth: 1,
        borderRadius: 5,
        barPercentage: 0.2,
        data : [mon,tue,wed,thu,fri,sat,sun],
      },
    ],
  }}
  labels="Dashboard Data"
  options={{
    scales: {
      x: {
        grid: {
          display:false,
        },
      },
    },
  }}
/>
</Card>
  </CardBody>
  <CardFooter>
  </CardFooter>
          </Card>
      </DoctorLayout>
      </Media>
      </MediaContextProvider>
      </>;
      }
      return (
        <>
        <MediaContextProvider>
          <Media greaterThanOrEqual='lg'>
              <DoctorLayout>
              <div class="flex-1" className='h-full w-full float-left'>
              <Skeleton height={30} width={350} style={{marginTop: 10, marginLeft:450}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 10, marginLeft:40}}/> */}
              <Skeleton height={120} width={580} inline={true} style={{marginTop: 40, marginLeft:30}}/>
              <Skeleton height={120} width={580} inline={true} style={{marginTop: 40, marginLeft:30}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 0, marginLeft:40}}/> */}
              <Skeleton height={300} width={580} inline={true} style={{marginTop: 40, marginLeft:30}}/>
              <Skeleton height={300} width={580} inline={true} style={{marginTop: 40, marginLeft:30}}/>
              </div>
              </DoctorLayout>
              </Media>
              <Media at='sm'>
              <DoctorLayout>
              <div class="flex-1" className='h-full w-full float-left'>
              <Skeleton height={30} width={250} style={{marginTop: 10, marginLeft:70}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 10, marginLeft:40}}/> */}
              {/* <Skeleton height={120} width={580} inline={true} style={{marginTop: 40, marginLeft:30}}/> */}
              <Skeleton height={120} count={2} width={355}  style={{marginTop: 40, marginLeft:20}}/>
              {/* <Skeleton height={40} width={200} inline={true} style={{marginTop: 0, marginLeft:40}}/> */}
              <Skeleton height={200} width={355} inline={true} style={{marginTop: 40, marginLeft:20}}/>
              <Skeleton height={200} width={355} inline={true} style={{marginTop: 40, marginLeft:20}}/>
              </div>
              </DoctorLayout>
              </Media>
              </MediaContextProvider>
              {/* </div> */}
        </>
      )
  }


export default DoctorDashboard;
