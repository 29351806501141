export const GET_DOCTOR_DASHBOARD_DATA_REQUEST = "GET_DOCTOR_DASHBOARD_DATA_REQUEST";
export const GET_DOCTOR_DASHBOARD_DATA_SUCCESS = "GET_DOCTOR_DASHBOARD_DATA_SUCCESS";
export const GET_DOCTOR_LIST_REQUEST = "GET_DOCTOR_LIST_REQUEST";
export const GET_DOCTOR_LIST_SUCCESS = "GET_DOCTOR_LIST_SUCCESS";
export const ADD_NEW_DOCTOR_REQUEST = "ADD_NEW_DOCTOR_REQUEST";
export const ADD_NEW_DOCTOR_SUCCESS = "ADD_NEW_DOCTOR_SUCCESS";
export const UPDATE_DOCTOR_REQUEST = "UPDATE_DOCTOR_REQUEST";
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_REQUEST = "DELETE_DOCTOR_REQUEST";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const CLINIC_SERVER_ERROR = "CLINIC_SERVER_ERROR";
export const GET_DOCTOR_SELF_APPOINTMENT_REQUEST = "GET_DOCTOR_SELF_APPOINTMENT_REQUEST";  
export const GET_DOCTOR_SELF_APPOINTMENT_SUCCESS = "GET_DOCTOR_SELF_APPOINTMENT_SUCCESS";
export const UPDATE_DOCTOR_SELF_PROFILE_REQUEST = "UPDATE_DOCTOR_SELF_PROFILE_REQUEST";
export const UPDATE_DOCTOR_SELF_PROFILE_SUCCESS = "UPDATE_DOCTOR_SELF_PROFILE_SUCCESS";
export const GET_DOCTOR_SELF_PATIENT_REQUEST = "GET_DOCTOR_SELF_PATIENT_REQUEST";  
export const GET_DOCTOR_SELF_PATIENT_SUCCESS = "GET_DOCTOR_SELF_PATIENT_SUCCESS";
export const GET_DOCTOR_SELF_PROFILE_REQUEST = "GET_DOCTOR_SELF_PROFILE_REQUEST";  
export const GET_DOCTOR_SELF_PROFILE_SUCCESS = "GET_DOCTOR_SELF_PROFILE_SUCCESS";
// export const DOCTOR_SERVER_ERROR = "DOCTOR_SERVER_ERROR";
