import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from "./routes/routes";
import './App.css';

function App() {


  return (
    <div className="App">
      <AppRouter/>
      

    </div>
  );
}

export default App;
